var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-body"},[(_vm.dataLoaded)?_c('div',_vm._l((_vm.sections),function(section,i){return _c('section',{key:'section-' + i},[(
          _vm.sectionsSettings[section].type == 'carousel' &&
          _vm.sectionsSettings[section].show
        )?_c('product-carousel',{attrs:{"section":section,"settings":_vm.sectionsSettings[section]}}):_vm._e(),(
          _vm.sectionsSettings[section].type == 'info' &&
          _vm.sectionsSettings[section].show
        )?_c('info-section',{attrs:{"section":section,"settings":_vm.sectionsSettings[section]}}):_vm._e(),(
          _vm.sectionsSettings[section].type == 'mini_info' &&
          _vm.sectionsSettings[section].show
        )?_c('mini-info-section',{attrs:{"section":section,"settings":_vm.sectionsSettings[section]}}):_vm._e(),(
          _vm.sectionsSettings[section].type == 'item_list' &&
          _vm.sectionsSettings[section].show
        )?_c('item-list',{attrs:{"section":section,"settings":_vm.sectionsSettings[section]}}):_vm._e(),(
          _vm.sectionsSettings[section].type == 'product_gallery' &&
          _vm.sectionsSettings[section].show
        )?_c('product-gallery-list',{attrs:{"section":section,"settings":_vm.sectionsSettings[section]}}):_vm._e(),(
          _vm.sectionsSettings[section].type == 'grid_view' &&
          _vm.sectionsSettings[section].show
        )?_c('grid-item-list',{attrs:{"section":section,"settings":_vm.sectionsSettings[section]}}):_vm._e(),(
          _vm.sectionsSettings[section].type == 'newsletter' &&
          _vm.sectionsSettings[section].show
        )?_c('newsletter',{attrs:{"section":section,"settings":_vm.sectionsSettings[section]}}):_vm._e(),(
          _vm.sectionsSettings[section].type == 'contactus' &&
          _vm.sectionsSettings[section].show
        )?_c('contact-us',{attrs:{"section":section,"settings":_vm.sectionsSettings[section]}}):_vm._e(),(
          _vm.sectionsSettings[section].type == 'footer1' &&
          _vm.sectionsSettings[section].show
        )?_c('footer-1',{attrs:{"section":section,"settings":_vm.sectionsSettings[section]}}):_vm._e()],1)}),0):_vm._e(),_vm._l((_vm.snackbars),function(item,i){return _c('snack-bar',{key:i,attrs:{"text":item.text,"index":i,"stype":item.stype,"forTime":item.forTime}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }